import * as _ from "lodash";
import { format } from "date-fns";
import React, { useState } from "react";
import styled, { css } from "styled-components";
import { HeaderGeneral } from "../components/header-general";
import { Layout } from "../components/layout";
import { CaptionText, DonwloadButton } from "../components/presse-kontakt";
import RTF from "../components/rtf";
import { EasyGrid } from "../components/styles/EasyGrid";
import { defaultPodcastUrls } from "../utils/const";
import { MaxWidth, READ_WIDTH, maxMedia, minMedia } from "../utils/style";
import { uiRegistry } from "../utils/ui-registry";
import GatsbyImage from "gatsby-image";
import { ContactForm } from "../components/contact-form";

declare namespace Q {
  export interface IRootObject {
    id: string;
    title: string;
    image: IImage;
    podcasts: IPodcastsItem[];
    articles: IArticlesItem[];
  }
  export interface IImage {
    fluid: IFluid;
  }
  export interface IFluid {
    base64: string;
    aspectRatio: number;
    src: string;
    srcSet: string;
    srcWebp: string;
    srcSetWebp: string;
    sizes: string;
  }
  export interface IPodcastsItem {
    title: string;

    podcastUrls: string[];
    link: string;
    published: string;
    image: IImage;
    description: IDescription;
    mp3: IMp3;
  }
  export interface IDescription {
    json: IJson;
  }
  export interface IJson {
    data: IData;
    content: IContentItem[];
    nodeType: string;
  }
  export interface IData {}
  export interface IContentItem {
    data: IData;
    content?: IContentItem[];
    nodeType: string;
    marks?: IMarksItem[];
    value?: string;
  }
  export interface IMarksItem {
    type: string;
  }
  export interface IMp3 {
    file: IFile;
  }
  export interface IFile {
    url: string;
  }
  export interface IArticlesItem {
    title: string;
    description: IDescription;
    link: string;
    published: string;
  }
}

type PodcastEntryProps = {
  className?: string;
  podcast: Q.IPodcastsItem;
};

const PodcastEntryCss = css`
  display: flex;
  max-width: 920px;
  margin: auto;

  ${maxMedia.tablet`
    flex-direction: column;
  `}

  > * {
    flex-shrink: 0;

    &:not(:last-child) {
      ${maxMedia.tablet`
        margin-bottom: 2rem;
      `}

      ${minMedia.tablet`
        margin-right: 2rem;
      `}
    }
  }

  > .playbutton {
    width: 64px;

    > img {
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      transform-origin: center center;
      &:hover {
        transform: scale(1.2);
      }
    }

    ${maxMedia.tablet`
      width: auto;
      display: flex;
      position: relative;
      margin-top: -5rem;
      height: 5rem;
      top: 7rem;

      > img {
        width: 2rem;
        height: 2rem;
      }
    `}
  }

  > .text {
    flex-grow: 1;
    flex-shrink: 1;
    > * {
      margin: 0;
    }

    ${maxMedia.tablet`
      padding-left: 3rem;
    `}
  }

  > .download {
    width: 200px;
  }
`;

export const PodcastEntry = styled(({ className, podcast }: PodcastEntryProps) => {
  const [showSharing, setShowSharing] = useState(false);

  return (
    <div className={className}>
      <div
        className="playbutton"
        onClick={() => {
          uiRegistry.play!(podcast.mp3.file.url);
        }}
      >
        <img src={require("../images/icon-play.svg")} width={64} height={64} />
      </div>
      <div className="text">
        <h4>{podcast.title}</h4>
        <CaptionText>{format(podcast.published, "YYYY-MM-DD")}</CaptionText>
        <p>
          <RTF content={podcast.description.json} />
        </p>
      </div>
      <div className="download">
        <EasyGrid vert>
          <DonwloadButton text="Download" href={podcast.mp3 ? podcast.mp3.file.url : "#"} target="_blank" download />
          {/* <DonwloadButton
              text="Teilen"
              icon="share"
              onClick={() => setShowSharing(!showSharing)}
            />
            {showSharing && <SharingButton />} */}

          {((podcast.podcastUrls || defaultPodcastUrls) as string[]).map(x => {
            const [head, ...tail] = x.split("+");
            const joinedTail = tail.join("+");
            return <DonwloadButton text={`${_.capitalize(head)} Podcast`} icon="subscribe" href={joinedTail} />;
          })}
        </EasyGrid>
      </div>
    </div>
  );
})`
  ${PodcastEntryCss}
`;

type ArticleEntryProps = {
  className?: string;
  article: Q.IArticlesItem;
};

const ArticleEntry = styled(({ className, article }: ArticleEntryProps) => {
  const foo = article.title;
  return (
    <div className={className}>
      <div className="playbutton" />
      <div className="text">
        <h4>{article.title}</h4>
        <CaptionText>{format(article.published, "YYYY-MM-DD")}</CaptionText>
        <p>
          <RTF content={article.description.json} />
        </p>
      </div>
      <div className="download">
        <DonwloadButton text={"Link zum\nArtikel"} href={article.link} target="_blank" icon="share" />
      </div>
    </div>
  );
})`
  ${PodcastEntryCss}
`;

export default ({ pageContext }: any) => {
  const node: any = pageContext.node;
  const title = node.title;
  const src = node.image.fluid.src;

  return (
    <Layout>
      <HeaderGeneral background={node.image.fluid.src} small>
        <p>Vortrag vom {node.shortTitle}</p>
        <h1>{title}</h1>
      </HeaderGeneral>

      <MaxWidth>
        <div className="content" style={{ paddingTop: "3rem" }}>
          <RTF content={node.content.json} />
        </div>
      </MaxWidth>

      <ContactForm />
    </Layout>
  );
};

// export const query = graphql`
//   query($slug: String!) {
//     markdownRemark(fields: { slug: { eq: $slug } }) {
//       html
//       frontmatter {
//         title
//       }
//     }
//   }
// `;
